import React from "react";
import img from "../../assets/404.webp";
import "./404.scss";
import Header from "../../component/header/header";
//done
function NotFound() {
  return (
    <>
      <Header />
      <div className="div-found">
        <div className="numb">
          <h1>404</h1>
          <h4>Not Found</h4>
          <div className="text">
            <p>This page you are looking for is not found.</p>
            <p>Please go to Home Page</p>
          </div>
        </div>
        <div className="not-found">{/* <h4>Not Found</h4> */}</div>
      </div>
    </>
  );
}

export default NotFound;
